import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes, FaEnvelope, FaPhone } from "react-icons/fa";
import logo from "../assets/tuma-logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Mini Header */}
      <div
        className={`bg-green-700 text-white p-2 w-full z-20 top-0 md:fixed ${
          scrollDirection === "down" ? "header-slide-up" : "header-slide-down"
        } hidden md:block`}
      >
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-1">
              <FaEnvelope />
              <span>james.koome@tumaagrienterprises.com</span>
            </div>
            <div className="flex items-center space-x-1">
              <FaPhone />
              <span>+254 720 427183</span>
            </div>
          </div>
          <nav className="hidden md:flex space-x-4">
            <ul className="flex space-x-4">
              <li>
                <Link
                  to="faq"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to="blog"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="support"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  Support
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Main Header */}
      <header
        className={`bg-white text-green-700 p-4 shadow-md fixed w-full z-10 top-0 md:top-10 ${
          scrollDirection === "down" ? "header-slide-up" : "header-slide-down"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center">
          <div className="p-2">
            <img
              src={logo}
              alt="Tuma Agricultural Enterprises"
              className="h-16"
            />
          </div>
          <nav className="hidden md:flex space-x-4">
            <ul className="flex space-x-4">
              <li>
                <Link
                  to="about"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="services"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="clients"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="focus:outline-none">
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
        {isOpen && (
          <nav className="md:hidden bg-white shadow-md">
            <ul className="flex flex-col space-y-2 p-4">
              <li>
                <Link
                  to="about"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="services"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="clients"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="faq"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to="blog"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="support"
                  smooth={true}
                  className="hover:text-yellow-300 cursor-pointer"
                  onClick={toggleMenu}
                >
                  Support
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </header>
    </>
  );
};

export default Header;
