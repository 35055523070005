import React from "react";

const services = [
  {
    title: "Fresh Produce Supply and Sourcing",
    description:
      "High-quality fresh produce to local and international markets, ensuring traceability, safety, and competitive prices.",
  },
  {
    title: "Value Addition of Agriculture Products",
    description:
      "Enhancing marketability and extending shelf life through packaging, grading, and processing.",
  },
  {
    title: "Supply of Animal Feeds",
    description:
      "Comprehensive range of animal feeds formulated with the highest nutritional standards for health and productivity.",
  },
  {
    title: "Supply of Agrochemicals",
    description:
      "Wide array of agrochemicals including fertilizers, pesticides, and herbicides for crop growth, protection, and disease management.",
  },
  {
    title: "Supply of Foodstuffs to Institutions",
    description:
      "Consistent quality and reliable delivery of various foodstuffs to schools and institutions.",
  },
];

const Services = () => {
  return (
    <section id="services" className="py-16 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-green-700 mb-8">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="p-6 bg-gray-100 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-green-600 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-700">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
