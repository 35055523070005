import React from "react";

const Footer = () => {
  return (
    <footer className="bg-green-700 text-white py-6">
      <div className="container mx-auto px-4 text-center">
        <p>
          &copy; 2024 Tuma Agricultural Enterprises Ltd. All rights reserved.
        </p>
        <p>
          Tel: +254 720 427183 | P.O BOX 2508-60200 MERU, KENYA | Email:
          tumaagrienterprises@gmail.com
        </p>
      </div>
    </footer>
  );
};

export default Footer;
