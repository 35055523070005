import React from "react";

const clients = [
  "Kemu University",
  "Meru University",
  "Heritage Hotels",
  "Kwikbasket",
  "ShambaniPro East Africa",
  "Meru County Government",
  "Kangaru School",
  "Embu County Government",
  "Zetech University",
  "Mitunguu Technical Training Institute",
  "Post Modern Farms Limited",
  "T-bin Limited",
  "Modesty Company Limited",
  "Ohospital Limited",
  "Embu Referral Hospital",
  "Ukuu Mission Hospital",
  "Triplinq Hotel",
  "Black Africa Hotel",
];

const Clients = () => {
  return (
    <section id="clients" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-green-700 mb-8">Our Clients</h2>
        <ul className="text-lg text-gray-700 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {clients.map((client, index) => (
            <li key={index} className="bg-white p-4 rounded-lg shadow-md">
              {client}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Clients;
