import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import heroBg1 from "../assets/herobackground.jpg";
import heroBg2 from "../assets/herobackgroundtwo.jpg";

const HeroSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <section className="hero-section h-[calc(100vh-4rem)] relative">
      <Slider {...settings}>
        <div className="h-[calc(100vh-4rem)] relative">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${heroBg1})` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          </div>
          <div className="h-full flex items-center justify-center relative z-10">
            <div className="text-center text-white p-8">
              <h1 className="text-5xl font-bold mb-4">
                Welcome to Tuma Agricultural Enterprises
              </h1>
              <p className="text-xl">
                Empowering Farmers for a Sustainable Future
              </p>
            </div>
          </div>
        </div>
        <div className="h-[calc(100vh-4rem)] relative">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${heroBg2})` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          </div>
          <div className="h-full flex items-center justify-center relative z-10">
            <div className="text-center text-white p-8">
              <h1 className="text-5xl font-bold mb-4">
                Quality Agricultural Products
              </h1>
              <p className="text-xl">
                Connecting Farmers, Suppliers, and Buyers
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default HeroSection;
