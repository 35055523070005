import React from "react";

const About = () => {
  return (
    <section id="about" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-green-700 mb-4">About Us</h2>
        <p className="text-lg text-gray-700">
          Tuma Agricultural Enterprises Ltd is a premier agricultural firm based in Kenya,
          dedicated to leveraging extensive knowledge of the local agriculture sector.
          We excel in connecting farmers, suppliers, and buyers to create sustainable and profitable relationships.
        </p>
      </div>
    </section>
  );
};

export default About;
